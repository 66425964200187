<template>
  <v-app>


    <v-app-bar
        app
        color="white"
        flat
    >

      <v-container class="py-0 fill-height">
        <v-toolbar-title>
        </v-toolbar-title>
        <span class="v-size--x-small text--disabled">(beta 1)</span>

      </v-container>
    </v-app-bar>

    <v-main>
      <v-container class="mx-auto pa-2 pa-sm-3">
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    drawer: false,
    selectedItem: 1
  }),
  methods: {
  }
};
</script>
