<template>
  <div>
    <v-card class="mx-auto rounded-xl" max-width="800px"
            :loading="this.loading"
    >
      <v-img
          src="https://images.squarespace-cdn.com/content/v1/573342b927d4bda40384c3ac/1613394143207-M3JSI3YVB1WGS607N6Q6/loan+shark.jpg?format=2500w"
          height="300px"
      >
        <v-row class="fill-height">
          <v-card-title>
          </v-card-title>

          <v-spacer></v-spacer>

          <v-card-title class="white--text pl-12 pt-12">
            <div class="text-h4 pl-12 pt-12">
              Meu Salario Real
            </div>
          </v-card-title>
        </v-row>
      </v-img>

      <v-list two-line>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-currency-usd
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-text-field v-on="mySalary"
                v-model="mySalary"
                :value="this.mySalary"
                label="Meu salario"
                required outlined
                @change="calculate"
                prefix="R$"
                suffix=".00"
                v-on:keyup="onKeyDown"
            ></v-text-field>
          </v-list-item-content>

        </v-list-item>

        <v-divider inset></v-divider>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-currency-usd
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>R$ {{ this.realSalaryMonth.toFixed(2) }}</v-list-item-title>
            <v-list-item-subtitle>Salário deste mês (salário + ppr)</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-currency-usd
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>R$ {{ this.realSalaryYearToDate.toFixed(2) }}</v-list-item-title>
            <v-list-item-subtitle>Salário real deste ano</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">
              mdi-currency-usd
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>R$ {{ this.realSalaryYearToDatePlusBenefits.toFixed(2) }}</v-list-item-title>
            <v-list-item-subtitle>Salário real deste ano (com auxílios - educação e academia)</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-card>

  </div>
</template>

<script>

// const openrouteservice = require("openrouteservice-js")

export default {
  data: () => ({
    pprData: null,
    factorMonth: 0,
    factorYTD: 0,
    mySalary: 0,
    realSalaryYearToDate: 0,
    realSalaryYearToDatePlusBenefits: 0,
    realSalaryMonth: 0,
    loading: false
  }),
  mounted () {
  },
  created () {
    const apiUrl = "https://sheetdb.io/api/v1/82t73ivufkmp5"
    fetch(apiUrl).then((res) => {
      console.log(res)
      res.json().then((json) => {
        this.pprData = json[0]
        this.factorMonth = parseFloat(json[0].factor_month)
        this.factorYTD = parseFloat(json[0].factor_ytd)
      })
    })
  },
  computed: {},
  methods: {
    calculate (data) {
      if (isNaN(data) || data === '') return
      console.log('calculando ...', this.mySalary, data)
      this.loading = true
      this.mySalary = parseFloat(data)
      this.realSalaryMonth = (this.factorMonth * this.mySalary) + this.mySalary

      const month = new Date().getMonth()

      this.realSalaryYearToDate = ((this.factorYTD * this.mySalary) + (this.mySalary * month)) / month

      this.realSalaryYearToDatePlusBenefits = ((this.factorYTD * this.mySalary) + ((this.mySalary + 800 + 120) * month))  / month

      this.loading = false
    },
    onKeyDown (ev) {
      console.log(ev.srcElement._value, this.mySalary)
      if (isNaN(ev.srcElement._value) || '') return
      this.calculate(ev.srcElement._value)
    }
  }
}
</script>
<style scoped>
.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
}

@media screen and (min-width: 720px) {
}

</style>
